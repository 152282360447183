<template>
  <v-card flat height="100%" class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <v-tabs left>
          <v-tab
            v-for="(time, key) in times"
            :key="key"
            @click="setTime(time.value)"
          >
            {{ time.text }}
          </v-tab>
        </v-tabs>
        <!-- <v-spacer></v-spacer>
        <div class="select-box-width">
          <location-select-box
            v-model="params.location_id"
            item-text="name"
            item-value="id"
            placeholder="Location"
            dense
            filled
            hide-details
            @change="getData()"
          ></location-select-box>
        </div> -->
      </div>
      <div class="d-flex flex-no-wrap align-center">
        <v-btn icon x-large @click="scrollList('previous')">
          <v-icon>mdi-less-than</v-icon>
        </v-btn>
        <div
          ref="featureVideoList"
          class="my-10 d-flex align-center hidden-scroll-x w-100"
        >
          <v-card
            v-for="(item, key) in items"
            :key="key"
            class="pa-2 mr-2"
            flat
            width="200"
            :class="item.active ? 'active-list-item' : ''"
          >
            <v-img
              :src="item.vertical_thumbnail_url"
              height="300"
              width="200"
              class="rounded-lg"
            ></v-img>
            <div class="my-2 mb-4 font-weight-bold caption">
              {{ item.scheduled_at | MIXINS_DATE }}
            </div>
            <div class="my-2 font-weight-bold body-2">
              {{ item.title }}
            </div>
            <div class="description-section">
              {{ item.description | TRUNCATE_TEXT }}
            </div>
            <v-card-actions class="mt-2 px-4">
              <v-btn
                rounded
                small
                depressed
                dark
                color="cyan"
                @click="sendDetails(item, key)"
              >
                Edit
              </v-btn>
              <v-spacer></v-spacer>
              <delete-item-dialog
                :itemID="item.id"
                :setup="setupDeleteDialog"
                :deleteURL="componentURL"
                :event-name="eventName"
              >
              </delete-item-dialog>
            </v-card-actions>
          </v-card>
          <v-btn v-show="showLoadMore" icon x-large @click="getData()">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
        <v-btn icon x-large @click="scrollList('next')">
          <v-icon>mdi-greater-than</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import moment from "moment";

const dailyVideosRepository = RepositoryFactory.get("daily-video");

const DeleteItemDialog = () =>
  import("@/components/miscellaneous/dialogs/DeleteItemDialog.vue");

export default {
  components: {
    DeleteItemDialog
  },
  filters: {
    TRUNCATE_TEXT: function(value) {
      if (value && value.length > 120) {
        return `${value.substring(0, 119)}...`;
      }

      return value;
    }
  },
  data: () => ({
    title: "Featured Videos",
    times: [
      {
        text: "Scheduled",
        value: "scheduled"
      },
      {
        text: "Past",
        value: "past"
      }
    ],
    items: [],
    listKey: 0,
    showLoadMore: true,
    setupDeleteDialog: {
      button: {
        rounded: true,
        small: true,
        depressed: true,
        dark: true,
        class: "mx-1",
        icon: false,
        label: "Delete",
        isIcon: false
      },
      card: {
        title: "Featured Video Delete Item"
      }
    },
    eventName: "reloadItems_featuredVideos",
    componentURL: "api/daily-videos",
    params: {
      page: 1,
      per_page: 5,
      latest: true,
      only_scheduled: true
    }
  }),
  methods: {
    getData() {
      this.loading = true;

      let params = this.params;

      dailyVideosRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: data }
          } = response;

          this.params.page++;

          if (this.params.page > data.last_page) {
            this.showLoadMore = false;
          }

          data.data.map(item => {
            item.active = false;
            item.scheduled_at = moment(moment.utc(item.scheduled_at).toDate()).format("YYYY-MM-DD HH:mm:ss");
            return item;
          });

          this.items.push(...data.data);
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scrollList(value) {
      let featureVideoList = this.$refs.featureVideoList;

      if (value === "next") {
        featureVideoList.scrollLeft += 500;

        return featureVideoList;
      }

      featureVideoList.scrollLeft -= 500;

      return featureVideoList;
    },
    sendDetails(item, index) {
      this.setItemToActive(index);

      GLOBAL_EVENT_BUS.$emit("showDetails_featuredVideo", item);
    },
    setTime(value) {
      this.tableKey++;

      this.items = [];

      this.params = {
        page: 1,
        per_page: 15,
        latest: true
      };

      if (value === "past") {
        delete this.params.only_scheduled;
        this.params.only_past = true;
      } else {
        delete this.params.only_past;
        this.params.only_scheduled = true;
      }

      this.getData();
    },
    reloadItemsEventBus() {
      GLOBAL_EVENT_BUS.$off(this.eventName);
      GLOBAL_EVENT_BUS.$on(this.eventName, () => {
        this.items = [];

        this.params = {
          page: 1,
          per_page: 5,
          latest: true
        };

        this.showLoadMore = true;

        this.getData();
      });
    },
    reloadItemsDeleteEventBus() {
      DELETE_ITEM_EVENT_BUS.$off(this.eventName);
      DELETE_ITEM_EVENT_BUS.$on(this.eventName, () => {
        this.items = [];

        this.params = {
          page: 1,
          per_page: 5
        };

        this.showLoadMore = true;

        this.getData();
      });
    },
    resetSelectedEventBus() {
      GLOBAL_EVENT_BUS.$off("resetSelected_featuredVideo");
      GLOBAL_EVENT_BUS.$on("resetSelected_featuredVideo", () => {
        this.resetActive();
      });
    },
    setupEventBus() {
      this.reloadItemsEventBus();
      this.reloadItemsDeleteEventBus();
      this.resetSelectedEventBus();
    },
    resetActive() {
      this.items.map(item => {
        item.active = false;

        return;
      });
    },
    setItemToActive(index) {
      this.resetActive();

      this.items[index].active = true;
    }
  },
  mounted() {
    this.getData();
    this.setupEventBus();
  }
};
</script>

<style lang="scss" scoped>
.description-section {
  height: 100px;
}
</style>
